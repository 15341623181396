import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import RjscPage from "@/views/RjscPage.vue";
import IrcPage from "@/views/IrcPage.vue";
import ServiceDetails from "@/views/comps/ServiceDetails.vue";
import CategoryList from "@/views/comps/CategoryList.vue";
import TermsCondition from "@/views/TermsConditionPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import ForgetPassword from "@/views/forget/ForgetPassword.vue";
import ResetPassword from "@/views/forget/ResetPassword.vue";

const routes = [
  {
    path: "/",
    name: "home-page",
    component: HomePage,
    meta: {requiredLogin: false}
  },
  {
    path: "/rjsc",
    name: "rjsc-page",
    component: RjscPage,
    meta: {requiredLogin: true}
  },
  {
    path: "/irc",
    name: "irc-page",
    component: IrcPage,
    meta: {requiredLogin: true}
  },
  {
    path: "/:slug",
    name: "service-details",
    component: ServiceDetails,
    meta: {requiredLogin: false}
  },
  {
    path: "/category/:slug",
    name: "category-list",
    component: CategoryList,
    meta: {requiredLogin: false}
  },
  {
    path: "/terms-condition",
    name: "terms-condition",
    component: TermsCondition,
    meta: {requiredLogin: false}
  },
  { 
    path:"/register", 
    name:"register-page",
    component:RegisterPage
  },
  { 
    path:"/login", 
    name:"login-page",
    component:LoginPage
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: ForgetPassword,
    meta: {requiredLogin: false}
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
    meta: {requiredLogin: false}
  },
];

const router = new createRouter({
  history:createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if(to.meta.AdminLogin){
      if(!localStorage.getItem('admin_id')){
          next({
              path:'/admin/login',
          });
      }else{
          next();
      }
  }else{
      next();
  }
})
router.beforeEach((to, from, next) => {
  if(to.meta.requiredLogin){
    if(localStorage.getItem('payment_status') == 0 || !localStorage.getItem('payment_status')){
      next({
          path:'/terms-condition',
      });
    }else{
      if(!localStorage.getItem('id')){
        next({
          path:'/login',
        });
      }else{
        next();
      }
    }
  }else{
      next();
  }

})



export default router;