<template>
    <div class="footer">
        <p>&copy; Copyright By OnlineZone</p>
    </div>
</template>
<style lang="scss">
.footer {
    height: 50px;
    background: linear-gradient(90deg,#86d169 ,#41b782);
    display: flex;
    align-items: center;
    padding: 0 30px;
    justify-content: center;
    p {
        margin: 0;
        color: #fff;
    }
}

</style>