<template>
    <main-header></main-header>
    <div class="form_area register">
        <div class="form_data">
            <h2 class="heading">Register</h2>
            <form @submit.prevent="RegisterSubmit">
                <p style="color: tomato;text-align: center;">{{ msg }}</p>
                <div class="_gp">
                    <label>Full Name</label>
                    <input type="text" v-model="name" placeholder="Dip Biswas">
                </div>
                <div class="_gp">
                    <label>Phone</label>
                    <input type="text" v-model="phone" placeholder="01741571104">
                </div>
                <div class="_gp">
                    <label>Email</label>
                    <input type="email" v-model="email" placeholder="example@gmail.com">
                </div>
                <div class="_gp">
                    <label>Password</label>
                    <div class="hide_show">
                        <input :type="pass_show_hide" v-model="password" placeholder="*****">
                        <img v-if="pass_show_hide == 'password'" @click="PassShowHideBtn('text')" src="@/assets/image/icon/show.png" alt="Show Password">
                        <img v-else @click="PassShowHideBtn('password')" src="@/assets/image/icon/hide.png" alt="Hide Password">
                    </div>
                </div>
                <div class="remember_forget">
                    <div class="remember">
                        <input type="checkbox">
                        <label>Rememeber me</label>
                    </div>
                    <router-link to="" class="link">Forget Password</router-link>
                </div>
                <button type="submit" class="action">Register</button>
                <div class="text_foot">
                    <span>Already have an account?</span>
                    <router-link to="/login" class="link">Sign in instead</router-link>
                </div>
            </form>
        </div>
    </div>
    <main-footer></main-footer>
</template>
<script>
import { ref } from 'vue';
import MainHeader from "./layout/MainHeader.vue"
import MainFooter from "./layout/MainFooter.vue"
import AuthStore from "@/store/AuthStore.js"

export default {
    name: 'register-page',
    components: { MainHeader, MainFooter },
    setup(){
        const pass_show_hide = ref('password');

        const PassShowHideBtn = (type) => {
            pass_show_hide.value = type;
        }

        const { name, phone, email, password, msg,  RegisterSubmit } = AuthStore();

        return {
            pass_show_hide,
            PassShowHideBtn,
            name:name(),
            phone:phone(),
            email:email(),
            password:password(),
            msg:msg(),
            RegisterSubmit
        }
    }
}
</script>
<style lang="scss">
.form_area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 30px;
    .form_data {
        max-width: 400px;
        width: 100%;
        padding: 20px;
        box-shadow: 0 4px 20px rgba(0,0,0,.15);
        border-radius: 8px;
        .heading {
            color: #444;
            font-size: 20px;
            border-bottom: 1px solid #ddd;
            padding-bottom: 8px;
            margin-bottom: 16px;
        }
        form {
            ._gp {
                display: block;
                margin-bottom: 16px;
                label {
                    display: block;
                    margin-bottom: 4px;
                    color: #444;
                    font-size: 14px;
                    font-weight: 600;
                }
                input {
                    outline: none;
                    border: 1px solid #ddd;
                    height: 40px;
                    width: 100%;
                    padding: 4px 12px;
                    border-radius: 4px;
                }
                .hide_show {
                    position: relative;
                    img {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        max-width: 24px;
                        width: 100%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }
            }
            .remember_forget {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
                .remember {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    input {
                        width: 16px;
                        height: 16px;
                    }
                }
                .link {
                    font-size: 15px;
                }
            }
            button.action {
                border: none;
                outline: none;
                cursor: pointer;
                color: #222;
                font-weight: 600;
                padding: 8px 20px;
                border-radius: 4px;
                width: 100%;
                display: block;
                margin-bottom: 20px;
                background: linear-gradient(45deg, #ffb1b1, #adc744);
                transition: all 0.3s ease 0s;
                -webkit-transition: all 0.3s ease 0s;
                &:hover {
                    background: linear-gradient(45deg, #ff9e9e, #adca36);
                    transition: all 0.3s ease 0s;
                    -webkit-transition: all 0.3s ease 0s;
                }
            }
            .text_foot {
                display: flex;
                column-gap: 8px;
                font-size: 15px;
                justify-content: center;
                span {
                    color: #444;
                }
            }
        }
    }
}
</style>