// import router from "@/router";
import axios from "axios";

const { reactive, readonly, computed } = require("vue");

const state = reactive({
    name:'',
    phone:'',
    email:'',
    password:'',
    payment_status:'',
    msg:'',
})

const getters = {
    name : () => {
        return computed({
            get(){ return state.name },
            set(value){ return state.name = value },
        })
    },
    phone : () => {
        return computed({
            get(){ return state.phone },
            set(value){ return state.phone = value },
        })
    },
    email : () => {
        return computed({
            get(){ return state.email },
            set(value){ return state.email = value },
        })
    },
    password : () => {
        return computed({
            get(){ return state.password },
            set(value){ return state.password = value },
        })
    },
    payment_status : () => {
        return computed({
            get(){ return state.payment_status },
            set(value){ return state.payment_status = value },
        })
    },
    msg : () => {
        return computed({
            get(){ return state.msg },
            set(value){ return state.msg = value },
        })
    }
}

const mutations = {
    
}

const actions = {
    RegisterSubmit : () => {
        if(state.name == '' || state.phone == '' || state.email == '' || state.password == ''){
            state.msg = 'Filed must be fill!!';
        }else{
            axios.post('/api/register',{
                name:state.name,
                phone:state.phone,
                email:state.email,
                password:state.password,
            })
            .then(response => {
                console.log(response);
                if(response.status == 200){
                    if(response.data.success == false){
                        window.location.href = process.env.VUE_APP_API_URL+'/api/payment/'+state.email;
                    }
                }
            })
        }
    },
    LoginSubmit : () => {
        axios.post('http://sobkisubazar.com/api/v2/auth/login',{
            email:state.email,
            password:state.password,
        })
        .then(response => {
            if(response.data.success == false){
                state.msg = response.data.message
            }else{
                localStorage.setItem('id',response.data.data.id);
                localStorage.setItem('name',response.data.data.name);
                localStorage.setItem('email',response.data.data.email);
                localStorage.setItem('payment_status',response.data.data.payment_status);
                localStorage.setItem('_token',response.data._token);
                window.location.href = "/";
            }
        })
    },
    Logout : () => {
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('payment_status');
        localStorage.removeItem('_token');
        window.location.href = "/";
    }
}

export default () => {
    return {
        state:readonly(state),
        ...getters,
        ...mutations,
        ...actions,
    }
}